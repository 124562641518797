import React from "react"
//import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"

class Slider extends React.Component {
  state = {
    current: 0,
  }

  change(index) {
    const moves = [
      [null, "prev", "next"],
      ["next", null, "prev"],
      ["prev", "next", null],
    ]
    const move = moves[index][this.state.current]
    if (move) this.slider.goTo(move)
  }

  intervalId = -1;

  componentDidMount() {
    this.slider = window.tns({
      container: ".my-slider",
      loop: true,
      items: 4,
      mouseDrag: true,
      nav: false,
      controls: false,
      autoWidth: true,
      center: true,
      edgePadding: 200,
      swipeAngle: false,
    })
    setTimeout(() => {
      //kind of an awful hack, but slider lib is not letting us do anything else
      var classname = document.getElementsByClassName("tns-item")
      for (var i = 0; i < classname.length; i++) {
        const id = classname[i].getAttribute("data-id")
        classname[i].addEventListener("click", () => this.change(id), false)
      }
    }, 500)

    this.intervalId = setInterval(() => {
      this.slider.goTo("next")
    }, 12000);

    this.slider.events.on("indexChanged", a => {
      this.setState({ current: a.displayIndex - 1 })
      clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.slider.goTo("next")
      }, 12000);
    }
    )
  }
  render() {
    return (
      <div className="my-slider only-desktop">
        {this.props.children.map((item, i) => (
          <div key={i} className="carousel-item" data-id={i}>
            {item}
          </div>
        ))}
      </div>
    )
  }
}

export default Slider
/**/
