import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./models.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import rewards from "../images/rewards.svg"
import strengthen from "../images/strengthen.svg"
import upgrade from "../images/upgrade.svg"
import community from "../images/community.svg"
import previous from "../images/previous.svg"
import next from "../images/next.svg"
import Benefit from "../components/benefit"
import Client from "../components/client"
import DesktopClient from "../components/desktop-client"
import Slider from "../components/slider"

const Models = () => {
  
  const [slider, setSlider] = useState(1)
  const change = n => {
    if (n > 0) setSlider(slider === 3 ? 1 : slider + 1)
    else setSlider(slider === 1 ? 3 : slider - 1)
  }

  useEffect(() => {
   // setInterval(() => change(+1), 3000)
  }, [])
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "models.png" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      model1: file(relativePath: { eq: "model1.png" }) {
        childImageSharp {
          fixed(height: 405) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      model2: file(relativePath: { eq: "model2.png" }) {
        childImageSharp {
          fixed(height: 405) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      model3: file(relativePath: { eq: "model3.png" }) {
        childImageSharp {
          fixed(height: 405) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout page="models">
      <SEO title="Models" description="Apply to join, and enjoy incredible products and experiences."/>
      <div className="flex-grid home-grid">
        <div className="models-box models-padder" />
        <div className="models-text">
          <div
            className="vertical-center models-subtitle"
          >
            As a member of the Neon Coat community, you are provided with
            complimentary resources for personal, social, and career success.
          </div>
        </div>
        <div style={{ flex: "1" }} className="models-box" />
        <div className="models-hero-image">
          <Img
            fluid={data.image.childImageSharp.fluid}
            alt="Neon Coat Models"
            durationFadeIn={100}
          />
        </div>
      </div>

      <div className="flex-grid">
        <div style={{ flex: "0 1 1000px", marginTop: "40px" }}>
          <div className="flex-grid benefit-grid">
            <Benefit title1="UPGRADE" title2="YOUR LIFESTYLE" icon={upgrade}>
              Enjoy complimentary experiences and gifts from premium brands that
              elevate your life
            </Benefit>
            <Benefit title1="JOIN A" title2="COMMUNITY" icon={community}>
              Connect with other models to form lasting friendships and explore
              your neighborhoods together
            </Benefit>
            <Benefit title1="STRENGTHEN" title2="YOUR BRAND" icon={strengthen}>
              Gain followers, increase engagement, and get noticed by brands to
              advance your career
            </Benefit>
            <Benefit title1="COLLECT" title2="REWARDS" icon={rewards}>
              Share your favorite Neon Coat brands on social media to gain perks
              and early access to special offers and events
            </Benefit>
          </div>
        </div>
      </div>

      <div className="flex-grid members-say">
        <div className="model-clients">
          <div className="brands-title-big">What Our Members Say</div>{" "}
          <div className="flex-grid" style={{ width: "100vw" }}>
            <div style={{ flex: "1" }} className="only-mobile-tablet">
              <img
                src={previous}
                className="prev-icon"
                onClick={() => change(-1)}
                alt="Neon Coat Previous"
              />
            </div>

            <div className="members-container only-mobile-tablet">
              <Client
                business="IRENE STEPANENKO"
                name="Q Models"
                image={data.model1.childImageSharp.fixed}
                active={slider === 1}
              >
                I am unconditionally grateful to Neon Coat for the opportunity
                to try so many new things. I’ve found my happy places all over
                NYC and had fun valuable time with my lovely friends. Love you!
              </Client>
              <Client
                business="CHRISTINA IONNO"
                name="Click"
                image={data.model2.childImageSharp.fixed}
                active={slider === 2}
              >
                Neon Coat is a great way for models to connect with amazing New
                York establishments, brands and most importantly, each other. It
                can be difficult as a model when you’re new in the city, and
                Neon Coat is a nice way of bringing the community together.
              </Client>
              <Client
                business="ADREENA THOMPSON"
                name="Fenton"
                image={data.model3.childImageSharp.fixed}
                active={slider === 3}
              >
                Neon Coat has always been the support keeping me afloat, giving
                me countless resources to help me stay on track with my
                physical, mental, and emotional health. This community brings us
                models together — which is so powerful, given that we are all
                out for the same jobs every day.
              </Client>
            </div>
            <div className="desktop-clients-container only-desktop">
              <Slider>
                <DesktopClient
                  business="Click"
                  name="CHRISTINA IONNO"
                  image={data.model2.childImageSharp.fixed}
                  active={slider === 2}
                >
                  Neon Coat is a great way for models to connect with amazing
                  New York establishments, brands and most importantly, each
                  other. It can be difficult as a model when you’re new in the
                  city, and Neon Coat is a nice way of bringing the community
                  together.
                </DesktopClient>
                <DesktopClient
                  business="Q Models"
                  name="IRENE STEPANENKO"
                  image={data.model1.childImageSharp.fixed}
                  active={slider === 1}
                >
                  I am unconditionally grateful to Neon Coat for the opportunity
                  to try so many new things. I’ve found my happy places all over
                  NYC and had fun valuable time with my lovely friends. Love
                  you!
                </DesktopClient>

                <DesktopClient
                  business="Fenton"
                  name="ADREENA THOMPSON"
                  image={data.model3.childImageSharp.fixed}
                  active={slider === 3}
                >
                  Neon Coat has always been the support keeping me afloat,
                  giving me countless resources to help me stay on track with my
                  physical, mental, and emotional health. This community brings
                  us models together—which is so powerful, given that we are all
                  out for the same jobs every day.
                </DesktopClient>
              </Slider>
            </div>
            <div style={{ flex: "1" }} className="only-mobile-tablet">
              <img
                src={next}
                className="next-icon"
                onClick={() => change(+1)}
                alt="Neon Coat Next"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Models
