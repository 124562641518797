import React from "react"
import "./benefit.css"

const Benefit = ({ title1, title2, children, icon }) => (
  <>
    <div className="benefit-container only-desktop">
      <img
        src={icon}
        alt="title1"
        style={{ display: "block", margin: "auto", marginBottom: "13px" }}
      />
      <div className="benefit-subtitle">
        {title1} {title2}
      </div>
      <div className="benefit-text">{children}</div>
    </div>
    <div className="benefit-container only-mobile-tablet">
      <img src={icon} alt="Neon Coat Benefit" style={{ position: "absolute" }} />
      <div className="benefit-subtitle">
        {title1} {title2}
      </div>
      <div className="benefit-text">{children}</div>
    </div>
  </>
)

export default Benefit
