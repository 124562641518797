import React from "react"
import Img from "gatsby-image"
import "./client.css"

const Client = ({ business, name, image, children, active }) => (
  <div className={`client-box slide-brands ${active ? "active" : ""}`}>
    <div className="client-image-container">
      <Img
        fixed={image}
        alt="Neon Coat Client"
        className="client-image"
        style={{ display: "block", margin: "40px auto", height: "200px", width: "200px" }}
      />
    </div>
    <div className="client-text-container">
      <div className="client-title" >
        {business}
      </div>
      <div className="client-title client-subtitle">{name}</div>
      <div className="client-content">{children}</div>
    </div>
  </div>
)

export default Client
