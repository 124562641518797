import React from "react"
import Img from "gatsby-image"
import "./desktop-client.css"

const Client = ({
  business,
  name,
  image,
  children,
  active,
  color,
  onClick,
}) => (
  <div className="desktop-client-container" onClick={() => onClick()}>
    <div
      className="desktop-client-box"
      style={{ backgroundColor: color ? "#eeeeee" : "#d3edef" }}
    >
      <Img
        fixed={image}
        alt="Client picture"
        className="desktop-client-image"
        style={{ display: "block" }}
        critial={true}
      />
      <div className="desktop-client-quote-char">“</div>
      <div className="desktop-client-quote-text">
        {children}
        <div className="desktop-client-quote-name">
          {name} |{" "}
          <span
            style={{
              fontStyle: "italic",
              fontFamily: "BrandonGrotesque-Regular",
            }}
          >
            {business}
          </span>
        </div>
      </div>
    </div>
  </div>
)

const Test = ({ business, name, image, children, active, color, onClick }) => (
  <div style={{ backgroundColor: "#fff", margin: "10px 40px", width: "800px" }}>
    <div
      className="desktop-client-box"
      style={{ backgroundColor: color ? "#eeeeee" : "#d3edef" }}
    >
      <img
        src={image.srcWebp}
        className="desktop-client-image"
        style={{ display: "block" }}
      />

      <div className="desktop-client-quote-char">“</div>
      <div className="desktop-client-quote-text">
        {children}
        <div className="desktop-client-quote-name">
          {name} |{" "}
          <span
            style={{
              fontStyle: "italic",
              fontFamily: "BrandonGrotesque-Regular",
            }}
          >
            {business}
          </span>
        </div>
      </div>
    </div>
  </div>
)
export default Test
